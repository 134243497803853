var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
              },
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pa-0",
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "nowrap",
                    "align-items": "baseline",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold ml-2",
                      staticStyle: { "font-size": "large" },
                    },
                    [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  ref: "toggleOpenIcon",
                  staticClass: "arrow_open",
                  staticStyle: {
                    "font-size": "13px",
                    color: "#0069d8",
                    "text-decoration": "underline",
                    cursor: "pointer",
                  },
                  on: { click: _vm.toggleOpen },
                },
                [_vm._v("\n        " + _vm._s(_vm.toggleText) + "\n      ")]
              ),
            ],
            1
          ),
          _c("v-container", { staticClass: "pt-0" }, [
            _c("div", { staticClass: "dotted-line" }),
            _vm.isOpen
              ? _c("table", { staticClass: "plan-table" }, [
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("td", { staticStyle: { border: "none" } }),
                        _c("td", { staticClass: "header-color" }, [
                          _c("p", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "header.CollateralPlanOptionTable.header1"
                                  )
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _c("td", { staticClass: "header-color" }, [
                          _c("p", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "header.CollateralPlanOptionTable.header2"
                                  )
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._l(_vm.records, function (record, key) {
                        return _c("tr", { key: key }, [
                          _c(
                            "td",
                            { staticClass: "header-color" },
                            _vm._l(
                              _vm.splitMessageLine(record.key),
                              function (message, index) {
                                return _c(
                                  "p",
                                  {
                                    key: index,
                                    staticClass: "ma-0",
                                    staticStyle: { "word-wrap": "break-word" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(message) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _c(
                            "td",
                            _vm._l(
                              _vm.splitMessageLine(record.value1),
                              function (message, index) {
                                return _c(
                                  "p",
                                  {
                                    key: index,
                                    staticClass: "ma-0",
                                    staticStyle: { "word-wrap": "break-word" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(message) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _c(
                            "td",
                            _vm._l(
                              _vm.splitMessageLine(record.value2),
                              function (message, index) {
                                return _c(
                                  "p",
                                  {
                                    key: index,
                                    staticClass: "ma-0",
                                    staticStyle: { "word-wrap": "break-word" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(message) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("ItemExplanationDialog", {
        attrs: {
          maxWidth: 320,
          showDialog: _vm.showItemExplanation,
          title: _vm.explanationItemTitle,
          text: _vm.explanationItemText,
          negativeButtonTitle: _vm.$t("button.close"),
          onClickNegativeButton: _vm.toggleShowItemExplanation,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }